import React from 'react'
import Helmet from 'react-helmet'
import { Container, Card, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

const MparesPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.filter(post => !post.node.frontmatter.hidden && post.node.frontmatter.contentType === 'bars')
  return (
    <Layout>
      <Helmet>
        <title>Μπάρες δημητριακών | Σπιτικό Παπακωνσταντίνου</title>
        <meta name="description" content="Μπάρες δημητριακών,ένα υγιεινό, θρεπτικό, και απολαυστικό σνακ για όλους!" />
      </Helmet>
      <Container className="product-archive mpares">
        <h1><b>Μπάρες δημητριακών</b></h1>
        <p>Οι μπάρες Σπιτικό είναι εξαίρετη επιλογή για ένα υγιεινό, θρεπτικό, και απολαυστικό σνακ για όλους!
          Ξεκινήστε την μέρα σας, υγιεινά και νόστιμα σε πολλές γεύσεις!
        Οι χειροποίητες μπάρες Σπιτικό χαρίζουν όλη την ενέργεια που χρειαζόμαστε και τονώνουν τον μεταβολισμό μας !</p>
        <p> <Link to="/files/EnergyBars-EN.pdf"
          onClick={(event) => { event.preventDefault(); window.open('/files/EnergyBars-EN.pdf'); }}>
          Click here for the English version of Energy Bars catalog.</Link></p>
        {posts.map(({ node: post }) => (
          <Link to={post.frontmatter.path}>
            <Card style={{ marginBottom: 10 }} key={post.id}>
              <CardBody>
                <img className="featuredImage" src={post.frontmatter.thumbnail} alt={post.frontmatter.title} />
                <CardTitle>{post.frontmatter.title}</CardTitle>
                <CardSubtitle style={{ marginBottom: 10 }}>{post.frontmatter.date}</CardSubtitle>
                <CardText>{post.excerpt}</CardText>
              </CardBody>
            </Card>
          </Link>
        ))}

      </Container>
    </Layout>
  )
}


export default MparesPage

export const mparesQuery = graphql`
  query MparesQuery {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___title] }) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          frontmatter {
            title
            contentType
            thumbnail
            date(formatString: "DD-MM-YYYY")
            path
            hidden
          }
        }
      }
    }
  }
`
